import React from "react";
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import DocumentIcon from '@material-ui/icons/ReceiptOutlined'
import Logout from '@material-ui/icons/ExitToApp'
const menuItems = {
    products: {
        outdoorProducts: [
            'Awnings',
            'Car Parks',
            'Fixed Structures',
            'Retractable Structures',
            'Artificial Grass',
            'Wood Plastic Composite',
            'Outdoor Furniture',
        ],
        indoorProducts: [
            'Blinds',
            'Wallpaper & Wallpanels',
            'Wooden & Vinyl Flooring',
        ]
        ,
        MoreOutdoor: [
            'Swings',
            'Pool Beds',
            'Outdoor Heaters',
            'Sofas',
            'Dining',
        ]
    },
    admin: [
        {
            name: 'Dashboard',
            icon: <DashboardIcon />
        },
        {
            name: 'Notifications',
            icon: <NotificationsNoneIcon />
        },
        {
            name: 'Projects',
            icon: <StorefrontIcon />
        },
        {
            name: 'Invoice Maker',
            icon: <DocumentIcon />
        },
        {
            name: 'Logout',
            icon: <Logout />
        },

    ],
    FbUrl: 'https://www.facebook.com/vectorsscalars',
    instaurl: 'https://www.instagram.com/vectorsnscalars'
}

export default menuItems;
