import React from "react";
import { Button } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const openWhatsApp = () => {
    const phoneNumber = '923312729444';
    const messageUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(messageUrl, '_blank');
};

const WhatsAppButton = () => {
    return (

        <Button
            variant="contained"
            style={{
                background: '#25D366', // WhatsApp green color
                position: 'fixed', bottom: '20px',
                right: '20px',
                zIndex: 1000,
                borderRadius: '50%',
                minWidth: '56px',
                minHeight: '56px',
            }}
            onClick={openWhatsApp}
        >
            <WhatsAppIcon style={{ color: 'white' }} />
        </Button>
    );
};

export default WhatsAppButton;
