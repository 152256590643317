import React from 'react';
import './App.css';
import MainScreen from "./containers/MainScreen";
import bgImage from './img/use_your_illusion.png';
import { Route, Switch, useLocation } from "react-router-dom";
import AdminScreen from "./containers/AdminScreen/AdminScreen";
import Login from "./containers/AdminScreen/AdminLogin";
import { useSelector } from 'react-redux';
import ExploreProductsMainScreen from './containers/ExploreProducts/ExploreProductMain';
import ContactUsPage from './containers/Contactus/contactus';
import ProductModal from './containers/ExploreProducts/productModal';
import Projects from './components/Footer/Projects/Projects';
import OpenProjects from './components/Footer/Projects/OpenProjects/openproject';
import InvoiceMaker from './components/QuotationMaker/InvoiceMaker';
import WhatsAppButton from './components/whatsappButton/whatsapp';

function App() {
    const isAdminActive = useSelector((state) => state.authadmin.isAdminActive);
    const isAdminActive2 = localStorage.getItem('idToken');
    const location = useLocation(); // Get the current route

    // Define paths where the WhatsApp button should not be displayed
    const excludedPaths = ['/login', '/admin'];

    return (
        <div style={styles.root}>
            <Switch>
                <Route path='/login' component={Login} />
                <Route
                    path='/Easel-invoice'
                    render={(props) => <InvoiceMaker {...props} InvoiceType="Easel" />}
                />
                {(isAdminActive || isAdminActive2) && <Route path='/admin' component={AdminScreen} />}
                <Route path='/prods' component={ExploreProductsMainScreen} />
                <Route path='/contactus' component={ContactUsPage} />
                <Route path='/products/:productTitle' component={ProductModal} />
                <Route path='/project/:projectTitle' component={OpenProjects} />
                <Route path='/projects' component={Projects} />
                <Route path='/' component={MainScreen} />
            </Switch>
            {/* Conditionally render WhatsAppButton */}
            {!excludedPaths.includes(location.pathname) && <WhatsAppButton />}
        </div>
    );
}

const styles = {
    root: {
        width: '100%',
        height: '100%',
        backgroundImage: 'url(' + bgImage + ')',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
    }
}

export default App;
