import React, { useEffect, useState } from 'react';
import { Modal, TextField, List, ListItem, ListItemText, Box, Typography, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getFirebase } from 'react-redux-firebase';

const SearchButtonModal = ({ isModalVisible, close }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [Query, setQuery] = useState('');

    useEffect(() => {
        console.log(isModalVisible);
    }, [isModalVisible]);

    const history = useHistory();

    const handleSearch = async (value) => {
        setQuery(value);
        console.log(value)
        if (value) {
            const suggestions = await fetchSuggestions(value.toLowerCase());
            setFilteredSuggestions(suggestions);
        } else {
            setFilteredSuggestions([]);
        }
    };

    const fetchSuggestions = async (searchTerm) => {
        const firebase = getFirebase();
        const firestore = getFirestore(firebase);
        const productsRef = collection(firestore, 'products'); // Assuming the collection name is 'products'
        const q = query(
            productsRef,
            where('title_lowercase', '>=', searchTerm),
            where('title_lowercase', '<=', searchTerm + '\uf8ff') // Adding the upper bound for partial matches
        );
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot)

        const suggestions = [];
        querySnapshot.forEach((doc) => {
            console.log(doc)
            const data = doc.data();
            if (data.title.toLowerCase().includes(searchTerm.toLowerCase()) || data.type.toLowerCase().includes(searchTerm.toLowerCase())) {
                suggestions.push({ id: doc.id, ...data })
            }
        });

        return suggestions;
    };

    const handleSuggestionClick = (item) => {
        if (item.type === 'Project') {
            history.push({
                pathname: `/project/${item.title.replace(/\s/g, '-').toLowerCase()}`,
                state: { data: item }
            });
        }
        else {
            history.push({
                pathname: `/products/${item.title}`,
                state: { data: item }
            });
        }
        close();
    };

    return (
        <Modal
            open={isModalVisible}
            onClose={close}
            aria-labelledby="search-modal-title"
            aria-describedby="search-modal-description"
        >
            <Box
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '500px',
                    backgroundColor: '#202020',
                    boxShadow: 24,
                    borderRadius: '12px',
                    overflow: 'hidden',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 20px',
                        borderBottom: '1px solid #ddd',
                        backgroundColor: '#202020',
                    }}
                >
                    <Typography variant="h6" id="search-modal-title" style={{ color: 'white' }}>
                        Search Products
                    </Typography>
                    <IconButton onClick={close} aria-label="close">
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </Box>
                <Box style={{ padding: '20px', background: '#202020' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Type to search..."
                        value={Query}
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon />,
                        }}
                        inputProps={{
                            autoCapitalize: "none" // Ensures lowercase input on mobile devices
                        }}
                        autocapitalize='none'
                        style={{
                            marginBottom: '20px',
                            backgroundColor: '#202020',
                            borderRadius: '8px',
                        }}
                    />
                    <List>
                        {filteredSuggestions.length > 0 ? (
                            filteredSuggestions.map((item, index) => (
                                <ListItem button key={index} onClick={() => handleSuggestionClick(item)}>
                                    <ListItemText primary={item.title} />
                                </ListItem>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', color: 'grey' }}>
                                No results found.
                            </Typography>
                        )}
                    </List>
                </Box>
            </Box>
        </Modal>
    );
};

export default SearchButtonModal;
