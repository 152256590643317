import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, IconButton, Tooltip } from '@material-ui/core/';
import ArrowBack from '@material-ui/icons/ArrowBack';
import makeStyles from '@material-ui/styles/makeStyles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core/';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import CircularProgress from '@material-ui/core/CircularProgress';
import './button.css'
import TempImage from './tempimage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FadeIn from 'react-fade-in';
import 'firebase/storage';
import { getFirestore, collection, where, query, getDocs } from "firebase/firestore";
import { getFirebase } from 'react-redux-firebase';

const ProductModal = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const ismobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [data, setData] = useState(location.state?.data || null); // State for product data
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  // Extract product name from the URL
  const pathname = window.location.pathname; // "/products/oval-dining"
  const productName = pathname.split("/").pop(); // "oval-dining"

  // Fetch product data conditionally
  useEffect(() => {
    const fetchData = async () => {
      console.log('useeffecet ranned', productName)
      if (!data) {
        const firebase = getFirebase();
        const firestore = getFirestore(firebase);
        const productsCollection = collection(firestore, 'products');
        const querySnapshot = await getDocs(query(productsCollection, where("title", "==",
          productName
            .split("%20").join(" ")
        )));
        console.log(productName
          .split("%20").join(" "), 'abcd')
        const fetchedData = querySnapshot.docs.map((doc) => doc.data());
        console.log(fetchedData, 'data')
        if (fetchedData.length > 0) {
          setData(fetchedData[0]); // Assume the first matching product is the desired one
        }
      }
    };

    fetchData();
  }, [data, productName]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is loaded
  }, []);

  const handleScroll = () => {
    const middlePosition = window.scrollY >= window.innerHeight / (data?.description.split('\n').length < 3 ? 25 : 3);
    setScrolledToBottom(middlePosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data]);

  if (!data) {
    return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%' }}>
      <CircularProgress style={{ color: '#c4b205' }} />
    </div>
  }
  return (
    <div className={classes.mainContainer} style={{ padding: isTablet ? "20px" : "0px" }}>
      <div style={{ marginBottom: ismobile ? "20%" : "8%" }}><Header /></div>
      <div
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: "3%",
          background: 'rgba(213, 213, 213)',
          zIndex: 2,
        }}
      >
        <div
          style={{
            paddingLeft: "3%",
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack style={{ fontSize: '1.3rem', color: 'white' }} />

        </div>
        <Typography variant='h6' style={{ fontWeight: 'bold', textTransform: 'capitalize', color: 'white', zIndex: 3 }}>
          {data.title}
        </Typography>


      </div>

      <div className={classes.container} style={{ height: isTablet ? "100%" : "100vh" }}>
        {!isTablet && (
          <>
            <div className={classes.divleft}>
              <img src={data.image} className={classes.image} alt='.' />
            </div>
            <div className={classes.divright}></div>
          </>
        )}
        <Grid container spacing={0}>
          {isTablet ? (
            <>
              <div style={{ position: 'fixed', left: 0, top: 0, width: "20%", height: "100%" }}>
                <img src={data.image} className={classes.image} alt='.' />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5%" }}>
                <Grid item xs={10}>
                  <div >
                    <Carousel showIndicators={false} autoFocus={true} useKeyboardArrows={true} emulateTouch={true} thumbWidth={100}>
                      {[
                        data.image, // Set first image from props.data.image
                        ...(data.moreimages || []).slice(0), // Keep the rest of the images from props.data.moreimages
                      ].map((image, index) => (
                        <div key={index} className={classes.Imgcont}
                          onMouseEnter={() => { setHoveredImage(image); setIsModalOpen(true); }}
                          onClick={() => { setHoveredImage(image); setIsModalOpen(true); }}>
                          <img src={image} className={classes.Scrollimges} alt='.' />
                        </div>
                      ))}
                    </Carousel>
                    {isModalOpen && (
                      <div
                        onMouseEnter={() => { setIsModalOpen(true); }}
                      >
                        <div style={{
                          position: "fixed",
                          top: "20%"
                          , left: "50%"
                          , transform: "translate(-50%,0%)"
                          , zIndex: '10'
                        }}>
                          <TempImage hoverimage={hoveredImage} close={() => { setIsModalOpen(false) }} height={'50vh'} width={'80vw'} />

                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </div>

              <Grid item xs={12}>
                <div style={{ position: "relative", zIndex: "1" }}>
                  <div style={{ position: 'relative', margin: '5%' }}>
                    {/* Add an overlay div */}
                    <div
                      style={{
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', /* Adjust the opacity as needed */
                        zIndex: 1,
                        pointerEvents: 'none',
                      }}
                    ></div>

                    {/* Place your Typography element over the overlay */}
                    <FadeIn><Typography
                      variant='body1'
                      style={{
                        color: 'white',
                        marginTop: '5%',
                        marginBottom: '5%',
                        overflowY: 'auto',
                        textAlign: 'justify',
                        maxHeight: '50vh',
                        position: 'relative', /* Ensure the text is on top of the overlay */
                        zIndex: 2, /* Set a higher z-index to place it above the overlay */
                        padding: "5%"
                      }}
                    >
                      {data.description}
                    </Typography></FadeIn>
                  </div>


                  {data.type !== 'Project' &&
                    <FadeIn><Typography variant='h5' className={classes.animate} style={{ marginTop: "10%", marginBottom: "20%" }}>
                      {data.warranty === 0 ? '' : `With warranty of ${data.warranty} years`}
                    </Typography></FadeIn>
                  }
                  {scrolledToBottom && data.type !== 'Project' && (
                    <p className="card-price" style={{ position: 'fixed', bottom: 0, right: 30 }}>
                      Price: {data.price}
                    </p>
                  )}

                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <div className={classes.divAbsoluteLeft}>
                  <Carousel className={classes.crousel} thumbHeight={200} showIndicators={false}>
                    {[
                      data.image, // Set the first image from props.data.image
                      ...(data.moreimages || []), // Keep the rest of the images from props.data.moreimages
                    ].map((image, index) => (
                      <div
                        key={index}
                        className={classes.Imgcont}
                        onMouseEnter={() => { setHoveredImage(image); setIsModalOpen(true); }}
                      >
                        <img src={image} className={classes.Scrollimges} alt='.' />

                      </div>
                    ))}
                  </Carousel>

                  {isModalOpen && (
                    <div
                      onMouseEnter={() => { setIsModalOpen(true); }}
                    >
                      <div style={{
                        position: "fixed",
                        top: "20%"
                        , left: "50%"
                        , transform: "translate(-50%,0%)"
                        , zIndex: '10'
                      }}>
                        <TempImage hoverimage={hoveredImage} close={() => { setIsModalOpen(false) }} />

                      </div>
                    </div>
                  )}
                </div>

              </Grid>
              <Grid item xs={8}>
                <div className={classes.divAbsoluteRight}>
                  <Typography variant='h6' style={{ fontWeight: "bold", textTransform: "capitalize", color: "white" }}>
                    {data.title}
                  </Typography>
                  <FadeIn> <Typography variant='body1' style={{
                    color: "white", marginTop: "5%",
                    overflowY: 'auto', textAlign: 'justify',
                    maxHeight: "50vh", backdropFilter: "blur(15px)"
                  }}>
                    {data.description}
                  </Typography></FadeIn>
                  <div style={{ bottom: 0, position: "fixed", right: 70, color: "#c4b205", display: "flex", flexDirection: "column", }}>

                    {data.type !== 'Project' &&
                      <FadeIn><Typography variant='h5' className={classes.animate}>
                        {data.warranty === 0 ? '' : `With warranty of ${data.warranty} years`}
                      </Typography></FadeIn>
                    }
                    {data.type !== 'Project' &&
                      <p class="card-price" >Price : {data.price}</p>
                    }

                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>


      </div>

    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    overflowY: "auto",
    borderRadius: '5px',
    outline: 'none',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'url("../../img/use_your_illusion.png")',
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat"
  },
  container: {
    position: "relative",
    width: "100%"
    , display: "flex"
    , flexDirection: "row",
  },
  divleft: {
    width: "20%",
    height: "100%",
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 'auto',
    overflow: 'hidden',
  },
  divright: {
    width: "80%",
    height: "100%",
  },
  divAbsoluteLeft: {
    position: "absolute",
    left: "10%",
    top: "10%",
    width: "30vw",
    height: "40vh",
  },
  divAbsoluteRight: {
    position: "absolute",
    marginRight: "5%",
    right: "0",
    top: "5%",
    width: "40vw",
    minHeight: "70vh",
    height: "auto",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
    , filter: 'blur(8px)',
  },
  Scrollimges: {
    height: "100%",
    objectFit: "cover",
    borderRadius: "5%",
  },
  crousel: {
  },
  Imgcont: {
    height: "40vh",
    display: "flex",
    alignItems: "center"
  },
  animate: {
    width: "100%",
    textAlign: "center",
    paddingTop: "2%",
    textTransform: 'uppercase',
    backgroundImage: `linear-gradient(-225deg, #c4b205 0%, yellow 29%, grey 67%, #c4b205 100%)`,
    backgroundSize: 'auto auto',
    backgroundClip: 'border-box',
    backgroundSize: '200% auto',
    color: '#fff',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: '$textclip 4s linear infinite',
    display: 'inline-block',
    fontSize: 20,
  },
  '@keyframes textclip': {
    to: {
      backgroundPosition: '200% center',
    },
  },

}))
export default ProductModal;
