import Header from "../../components/Header";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import 'firebase/storage';
import { getFirestore, collection, where, query, getDocs } from "firebase/firestore";
import { getFirebase } from 'react-redux-firebase';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from "../../components/Footer/Footer";
import image from '../../img/use_your_illusion.png';
import './button.css';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { SetExploreProductsData } from "../../store/actions/ExploreproductsScreenAction";

function ExploreProductsMainScreen(props) {
  const [data, setData] = useState("");

  useEffect(() => {
    const value = props.location?.state
      ? props.location.state.data
      : window.location.pathname.split("/").pop().split("%20").join(" ");
    setData(value);
    console.log('value is ', value)
  }, [props.location, window.location.pathname]);

  const dispatch = useDispatch();
  const ExplorescreenProducts = useSelector(state => state.exploreproductsData[data] || []);
  const history = useHistory();
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [isloaded, setisloaded] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [Modaldata, setModaldata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setisloaded(true);
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');
      const productsArray = []; // Initialize an empty array to accumulate the products
      if (data !== undefined) {
        try {
          //           console.log('inside conditions :', data)
          // here i can also change data to :props.location?.state
          // ? props.location.state.data
          // : window.location.pathname.split("/").pop().split("%20").join(" ")
          const querySnapshot = await getDocs(query(productsCollection, where("type", "==", data)));

          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            productsArray.push(docData); // Add each product to the array
          });

          // Dispatch action to store products in Redux state
          dispatch(SetExploreProductsData({
            type: props.location?.state
              ? props.location.state.data
              : window.location.pathname.split("/").pop().split("%20").join(" "), products: productsArray
          }));
          setProducts(productsArray); // Update the state with the accumulated products
          setisloaded(false);
        } catch (error) {
          console.error(error);
          setisloaded(false);
        }
      }
    }
    if (!ExplorescreenProducts.length || ExplorescreenProducts.some(product => product.type !== data)) {
      fetchData();
      console.log('if runed', !ExplorescreenProducts.length, ExplorescreenProducts.some(product => product.type !== data))
    } else {
      setProducts(ExplorescreenProducts);
      console.log('if not runed', ExplorescreenProducts)
    }
  }, [data, dispatch, ExplorescreenProducts]);


  const handleMore = (index) => {
    console.log('uindea', products[index].title);
    history.push({
      pathname: `/products/${products[index].title}`,
      // pathname: `/products/${products[index].title.replace(/\s/g, '-').toLowerCase()}`
      state: { data: products[index] }
    });
    window.scrollTo(0, 0);
  }

  return (
    <div style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: "fixed" }}>
      <Header />
      <AppBar position="static" className={isMobile || isTab ? classes.appBar : classes.appBar1}>
        <Toolbar variant="prominent">
          <div style={{ paddingLeft: "2%", display: "flex", alignItems: 'center' }} onClick={() => { history.goBack(); }}><ArrowBack style={{ color: "white", cursor: "pointer", fontSize: "1.5em" }} /></div>
          <Typography variant="h6" component="div" style={{ paddingLeft: "1%", fontSize: '15px' }}>
            {data?.toUpperCase()}
          </Typography>
        </Toolbar>
      </AppBar>

      {isloaded && <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress style={{ color: "#c4b205" }} />
      </div>}

      <Grid container>
        {products?.map((product, index) => (
          <Grid key={index} item xs={12} sm={4} md={4} className={classes.root}>
            <div className={classes.container}>
              <div className="img-list">
                <div className="img-placeholder">
                  <img src={product.image} className={classes.image} alt="Product" />
                  <div className="hover-text">{product.description}</div>
                </div>
                <div className={classes.priceTag}>Price : &nbsp;{product.price}</div>
              </div>
              <div className='title' style={{ marginBottom: "10%" }}>{product.title}</div>
              <div style={{ position: "absolute", bottom: "0", width: "100%", marginBottom: "10px" }}>
                <Button className="btne" onClick={() => { handleMore(index) }}><span>Click For More</span></Button>
              </div>
            </div>
          </Grid>
        ))}
        <div style={{ marginTop: "10%" }}>
          <Footer />
        </div>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", justifyContent: "center", padding: "2%"
  },
  appBar: {
    backgroundColor: 'grey', // Replace with your desired custom color
    position: "sticky",
    marginBottom: "5%",
    marginTop: "50px",
    top: 70,
    opacity: 0.9
  },
  appBar1: {
    backgroundColor: 'grey', // Replace with your desired custom color
    position: "sticky",
    top: 99,
    marginTop: "100px",
    opacity: 0.9
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 6, /* Number of lines to show */
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    marginBottom: "5px"
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
    border: "1px solid grey",
    borderRadius: "1%",
    width: "100%",
    padding: "1%"
  },
  image: {
    width: '100%',
    height: '100%', /* Make the image cover the entire container */
    objectFit: 'cover',
    borderRadius: "2%"
  },
  priceTag: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '4px 8px',
    textAlign: "center",
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));

export default ExploreProductsMainScreen;
